import { AggressAttempt } from './types'

export const addOrUpdateAggressAttempt = (
  stateAggressAttempts: AggressAttempt[],
  aggressAttemptsToAdd: AggressAttempt[]
) => {
  const unchangedAggressAttempts = stateAggressAttempts.filter(
    (t) =>
      !aggressAttemptsToAdd.find(
        ({ takerOrderId }) => t.takerOrderId === takerOrderId
      )
  )
  return [...unchangedAggressAttempts, ...aggressAttemptsToAdd]
}
