import { ICellEditorParams, ICellRendererParams } from '@ag-grid-community/core'
import React, { memo } from 'react'
import { OrderType } from '../store/order/types'
import { Security } from '../store/securities/reducer'
import { OrderManager, useManageOneOrderType } from './hooks/useManageMyOrders'

interface WrappedOrderFieldProps {
  field: 'price' | 'size' | 'spread'
  security: Security | undefined
  manager: OrderManager
}

type OrderFieldType = WrappedOrderFieldProps['field']

export const withOrderField = <C extends WrappedOrderFieldProps>(
  Component: React.ComponentType<C>,
  type: OrderType,
  field: OrderFieldType
) => {
  const WithOrderFieldComponent = (
    props: ICellRendererParams<Security> | ICellEditorParams<Security>
  ) => {
    const manager = useManageOneOrderType(type)
    const { data: security, ...rest } = props
    return (
      // @ts-ignore
      <Component
        field={field}
        security={security}
        manager={manager}
        {...rest}
      />
    )
  }

  return memo(WithOrderFieldComponent)
}
type OrderManagerChild = { manager: OrderManager; field?: OrderFieldType }
export function withOrderManager<C>(
  Component: React.FunctionComponent<C & OrderManagerChild>,
  type: OrderType,
  field: OrderFieldType
) {
  const WithOrderManagerComponent = (props: C) => {
    const manager: OrderManager = useManageOneOrderType(type)
    return <Component {...props} manager={manager} field={field} />
  }
  return memo(WithOrderManagerComponent)
}
