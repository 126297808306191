import { ColDef, ICellRendererParams } from '@ag-grid-community/core'
import { faCircleInfo } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { noop } from 'rxjs'
import OrderStatusTdContent from '../../../../containers/TradingList/cells/OrderStatusTdContent'
import { dateFormatter } from '../../../../helpers/formatting'
import DepthOfMarketControls from '../DepthOfMarketControls'

export const TAKER_ORDER_ID = 'takerOrderId'
export const MAKER_ORDER_ID = 'makerOrderId'
export const STATUS = 'status'

export const DID_NOT_MATCH_REASON = 'didNotMatchReason'
export const TAKER_OPERATOR = 'takerOperator'
export const MAKER_OPERATOR = 'makerOperator'
export const TAKER_TRADER = 'takerTrader'
export const MAKER_TRADER = 'makerTrader'
export const TAKER_VENUE = 'takerVenue'
export const MAKER_VENUE = 'makerVenue'
export const TAKER_TICKET_ID = 'takerTicketId'
export const MAKER_TICKET_ID = 'makerTicketId'
export const TAKER_INTERFACE = 'takerInterface'
export const MAKER_INTERFACE = 'makerInterface'
export const TAKER_ORDER_PARAMS = 'takerOrderParams'
export const MAKER_ORDER_PARAMS = 'makerOrderParams'
export const TAKER_ORDER_STATUS_UPDATES = 'takerOrderStatusUpdates'
export const MAKER_ORDER_STATUS_UPDATES = 'makerOrderStatusUpdates'

export const ORDER_TIME = 'orderTime'
export const ORDER_DATE = 'orderDate'
export const MAKER_EXTERNAL_ORDER_ID = 'makerExternalOrderId'
export const MAKER_VENUE_NUMBER = 'makerVenueNumber'
export const TAKER_COMPONENT_LOCATION = 'takerComponentLocation'
export const MAKER_COMPONENT_LOCATION = 'makerComponentLocation'

export const SECURITY_BOARD_LABEL = 'securityBoardLabel'
export const ISIN = 'isin'
export const ORDER_TYPE = 'orderType'
export const PRICE = 'price'
export const SPREAD = 'spread'
export const COMPLETED_AMT = 'completedAmt'
export const INITIAL_AMT = 'initialAmt'
export const REMAINING_AMT = 'remainingAmt'
export const SECONDS_LIVE = 'secondsLive'
export const SECURITY_ID = 'securityId'
export const CANCEL_TIME = 'cancelTime'
export const IS_LIVE = 'isLive'
export const WAS_CANCELED = 'wasCanceled'
export const WAS_REJECTED = 'wasRejected'
export const TRADER = 'trader'
export const TAKER_CUSTOMER = 'customer'
export const SPECIFIC = 'specific'
export const TRADE_RECORD_ID = 'tradeRecordId'
export const ACTIVITY_TICKER = 'activityTicker'
export const FIX_VENUE_COUNT = 'fixVenueCount'
export const BASIS_POINTS = 'basisPoints'
export const BROKERAGE = 'brokerage'

export const getCustomizableColumns = (
  displayModal: boolean,
  toggleModal: (b: boolean) => void,
  setSelectedRow: (s: string) => void
): ColDef[] => [
  {
    cellRenderer: DepthOfMarketControls,
    colId: 'DepthTools',
    headerName: 'Depth',
    headerComponent: () => null,
    hide: false,
    menuTabs: [],
    resizable: false,
    suppressAutoSize: true,
    suppressPaste: true,
    maxWidth: 22,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    pinned: 'left'
  },
  {
    colId: ORDER_TIME,
    field: ORDER_TIME,
    headerName: 'Order Time',
    valueFormatter: dateFormatter,
    tooltipField: ORDER_TIME
  },
  {
    colId: SECONDS_LIVE,
    field: SECONDS_LIVE,
    headerName: 'Seconds Live'
  },
  {
    colId: STATUS,
    field: STATUS,
    headerName: 'Status',
    tooltipField: STATUS,
    width: 150,
    wrapText: true,
    autoHeight: true,
    cellRenderer: OrderStatusTdContent,
    cellRendererParams: (params: ICellRendererParams) => {
      const { status, securityId, makerTicketId } = params.data
      return {
        status,
        icon: status === 'Traded' ? faCircleInfo : undefined,
        title: 'View Trade Confirm',
        testId: `attempts-${securityId}-${makerTicketId}`,
        onClick: () => {
          if (makerTicketId) {
            setSelectedRow(makerTicketId.toString())
            toggleModal(!displayModal)
          }
        }
      }
    }
  },
  {
    colId: DID_NOT_MATCH_REASON,
    field: DID_NOT_MATCH_REASON,
    headerName: 'Did Not Match Reason',
    tooltipField: DID_NOT_MATCH_REASON,
    width: 200,
    wrapText: true,
    autoHeight: true
  },
  {
    colId: TAKER_TRADER,
    field: TAKER_TRADER,
    headerName: 'Taker Trader'
  },
  {
    colId: TAKER_OPERATOR,
    field: TAKER_OPERATOR,
    headerName: 'Taker Operator',
    tooltipField: TAKER_OPERATOR
  },
  {
    colId: ORDER_TYPE,
    field: ORDER_TYPE,
    headerName: 'Order Type'
  },
  {
    colId: INITIAL_AMT,
    field: INITIAL_AMT,
    headerName: 'Initial Amt'
  },
  {
    colId: COMPLETED_AMT,
    field: COMPLETED_AMT,
    headerName: 'Completed Amt'
  },
  {
    colId: SECURITY_BOARD_LABEL,
    field: SECURITY_BOARD_LABEL,
    headerName: 'Security',
    tooltipField: SECURITY_BOARD_LABEL
  },
  {
    colId: ISIN,
    field: ISIN,
    headerName: 'ISIN'
  },
  {
    colId: MAKER_OPERATOR,
    field: MAKER_OPERATOR,
    headerName: 'Maker Operator',
    tooltipField: MAKER_OPERATOR
  },
  {
    colId: SPREAD,
    field: SPREAD,
    headerName: 'Spread'
  },
  {
    colId: PRICE,
    field: PRICE,
    headerName: 'Price'
  },
  {
    colId: BROKERAGE,
    field: BROKERAGE,
    headerName: 'Brokerage'
  },
  {
    colId: BASIS_POINTS,
    field: BASIS_POINTS,
    headerName: 'Basis Points'
  },
  {
    colId: CANCEL_TIME,
    field: CANCEL_TIME,
    headerName: 'Cancel Time',
    valueFormatter: dateFormatter,
    tooltipField: CANCEL_TIME
  },
  {
    colId: REMAINING_AMT,
    field: REMAINING_AMT,
    headerName: 'Remaining Amt'
  },
  {
    colId: IS_LIVE,
    field: IS_LIVE,
    headerName: 'Live'
  },
  {
    colId: TRADE_RECORD_ID,
    field: TRADE_RECORD_ID,
    headerName: 'Trade Record id'
  },
  {
    colId: TAKER_TICKET_ID,
    field: TAKER_TICKET_ID,
    headerName: 'Taker Ticket Id'
  },
  {
    colId: MAKER_TICKET_ID,
    field: MAKER_TICKET_ID,
    headerName: 'Maker Ticket Id'
  },
  {
    colId: TAKER_INTERFACE,
    field: TAKER_INTERFACE,
    headerName: 'Taker Interface'
  },
  {
    colId: TAKER_VENUE,
    field: TAKER_VENUE,
    headerName: 'Taker Venue'
  },

  {
    colId: TAKER_CUSTOMER,
    field: TAKER_CUSTOMER,
    headerName: 'Taker Customer'
  },
  {
    colId: TAKER_ORDER_ID,
    field: TAKER_ORDER_ID,
    headerName: 'Taker Order Id'
  },
  {
    colId: SPECIFIC,
    field: SPECIFIC,
    headerName: 'Specific'
  },
  {
    colId: MAKER_ORDER_ID,
    field: MAKER_ORDER_ID,
    headerName: 'Maker Order Id'
  },
  {
    colId: MAKER_INTERFACE,
    field: MAKER_INTERFACE,
    headerName: 'Maker Interface'
  },
  {
    colId: MAKER_VENUE,
    field: MAKER_VENUE,
    headerName: 'Maker Venue'
  }
]

export const autoSizedColumnIds = getCustomizableColumns(false, noop, noop)
  .filter(
    (column) =>
      column.colId && ![STATUS, DID_NOT_MATCH_REASON].includes(column.colId)
  )
  .map((column) => column.colId!)
