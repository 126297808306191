import { Action } from 'redux'

const NAMESPACE = `aggressAttempts.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const AGGRESSATTEMPT_FETCH = `${NAMESPACE}fetch`
export interface AggressAttemptFetchAction extends Action {
  type: typeof AGGRESSATTEMPT_FETCH
}

export const AGGRESSATTEMPT_FETCH_FAIL = `${NAMESPACE}fetch`
export interface AggressAttemptFetchFailAction extends Action {
  type: typeof AGGRESSATTEMPT_FETCH_FAIL
  payload: any
  error: true
}

/**
 * Add Items
 */
export const AGGRESSATTEMPT_ADD = `${NAMESPACE}addAggressAttempt`
export interface AggressAttemptAddAggressAttemptAction extends Action {
  type: typeof AGGRESSATTEMPT_ADD
  payload: AggressAttempt[]
}

export const AGGRESSATTEMPT_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface AggressAttemptFetchCancelAction extends Action {
  type: typeof AGGRESSATTEMPT_FETCH_CANCEL
}

export type AggressAttemptAction =
  | AggressAttemptFetchAction
  | AggressAttemptAddAggressAttemptAction
  | AggressAttemptFetchCancelAction

//
// DATA MODELS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export interface AggressAttempt {
  id: number
  takerOrderId: number
  makerOrderId: number | undefined
  status: string
  didNotMatchReason: string
  takerTrader: string
  takerOperator: string
  makerOperator: string
  takerVenue: string
  makerVenue: string
  takerTicketId: number | undefined
  makerTicketId: number | undefined
  takerInterface: string
  makerInterface: string
  takerOrderParams: string
  makerOrderParams: string
  takerOrderStatusUpdates: string
  makerOrderStatusUpdates: string
  orderTime: string
  orderDate: string
  makerExternalOrderId: string
  makerVenueNumber: number | undefined
  takerComponentLocation: string
  securityBoardLabel: string
  iSIN: string
  orderType: string
  price: string
  spread: string
  completedAmt: number
  initialAmt: number
  remainingAmt: number
  secondsLive: string
  securityId: number
  cancelTime: Date
  isLive: boolean
  wasCanceled: string
  wasRejected: string
  trader: string
  customer: string
  specific: string
  isEligibleResubmit: string
  tradeRecordId: number | undefined
  activityTicker: string
  fixVenueCount: number
  basisPoints: string
  brokerage: string
}
