import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../helpers/hub'
import {
  HeartbeatReceivedAction,
  receiveHeartbeat,
  successfulHeartbeat
} from './actions'

const heartbeatEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType('heartbeat.HeartbeatReceived'),
    switchMap((action: HeartbeatReceivedAction) => {
      return getHub().invoke('SingleHeartbeat').pipe(map(successfulHeartbeat))
    })
  )

const beginHeartbeatingEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType('heartbeat.BeginHeartbeating'),
    mergeMap(() => {
      return getHub().stream('Heartbeat').pipe(map(receiveHeartbeat))
    }),
    takeUntil(action$.pipe(ofType('ws.force-reload')))
  )

export default combineEpics(beginHeartbeatingEpic, heartbeatEpic)
