import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useOpenFin } from '../../app/openFinContext'
import { useAppSelector } from '../../store/types'
import { getWatchList } from '../../store/watchList/selectors'
import {
  getHasTradingRights,
  showLTSetting
} from '../../store/webSettings/selectors'

export const useListTradingNavigation = (watchlistId?: number) => {
  const hasTradingRights = useAppSelector(getHasTradingRights)
  const showLT = useAppSelector(showLTSetting)
  const watchlists = useAppSelector(getWatchList)

  const { fin, renderWindowCheck } = useOpenFin()

  const navigate = useNavigate()

  const watchlist = useMemo(() => {
    return watchlists?.find((wl) => wl.id === watchlistId)
  }, [watchlists, watchlistId])

  const tradeWatchlist = useCallback(() => {
    // not sure if this is exactly right,
    // but we don't show it in the dropdown if they can't edit it
    if (!watchlist?.canEdit || !(hasTradingRights && showLT)) {
      console.error(
        "Can't trade this list because you don't have access at this time."
      )
    }
    if (watchlistId) {
      const path = `ListTrading/${watchlistId}`
      fin ? renderWindowCheck(path, 0) : navigate(path)
    } else {
      console.error("Can't trade this list because it has no id.")
    }
  }, [watchlist, watchlistId])

  return {
    hasTradingRights,
    showLT,
    tradeWatchlist,
    wlName: watchlist?.name
  }
}
