import {
  GetRowIdFunc,
  GridApi,
  IServerSideGetRowsParams
} from '@ag-grid-community/core'

import { AgGridReact } from '@ag-grid-community/react'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { MARKET_VOLUME } from '../../containers/BondList/columnDefs'
import OrderTooltip from '../../containers/BondList/OrderTooltip'
import SecurityDetailsModalLazy from '../../containers/DepthOfMarket/DetailsModal/SecurityDetailsModalLazy'

import {
  fetchSecurities,
  setCurrentPage,
  setWatchListId
} from '../../store/securities/actions'
import {
  DYNAMIC_WATCHLISTS,
  SECURITY_PAGE_SIZE
} from '../../store/securities/helpers'
import { Security, SecurityStaticData } from '../../store/securities/reducer'
import {
  getCurrentPage,
  getDisplayedSecurityIds,
  getStaticDataForPage,
  getWatchlistId,
  hasError,
  isPageLoaded
} from '../../store/securities/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getWatchList } from '../../store/watchList/selectors'
import { getIsAdmin } from '../../store/webSettings/selectors'
import DepthDetailRenderer from '../DepthOfMarket/DepthDetailRenderer'

import styles from './grid.module.scss'

import { GridRef, Props } from './types'
import { useGridControls } from './useGridControls'

export type PendingSecurityDataCall = {
  page: number
  params: IServerSideGetRowsParams<SecurityStaticData>
}

const defaultColumnDefinitions = {
  minWidth: 10,
  lockPinned: true,
  menuTabs: [],
  suppressNavigable: true,
  sortable: false
}

const components = {
  orderTooltip: OrderTooltip
}

const getRowId: GetRowIdFunc<Security> = ({ data }) => {
  return `${data?.id || ''}`
}

const Grid = forwardRef<GridRef, Props>(
  (
    {
      gridIndex,
      onSelectionChanged,
      onRowDoubleClicked,
      canEditWatchlist,
      setRowsAreOpen,
      watchlistName
    },
    handle
  ) => {
    const gridRef = useRef<AgGridReact<Security>>(null)
    const isAdmin = useAppSelector(getIsAdmin)

    const dispatch = useAppDispatch()
    const error = useAppSelector(hasError)(gridIndex)
    const currentPage = useAppSelector(getCurrentPage)(gridIndex)
    const watchlists = useAppSelector(getWatchList)
    const watchlistIdSelected = useAppSelector(getWatchlistId)(gridIndex)
    const getIsPageLoaded = useAppSelector(isPageLoaded)

    const loadedSecuritiesCount =
      useAppSelector((state) => getDisplayedSecurityIds(state)(gridIndex))
        ?.length ?? 0

    // this will return a new function every time one of the functions in its dependency list changes
    const getPageOfStaticSecurities = useAppSelector(getStaticDataForPage)

    // make loaded securities available in the datasource's getRows fn
    const securitiesGetter = useRef(getPageOfStaticSecurities)
    useEffect(() => {
      securitiesGetter.current = getPageOfStaticSecurities
    }, [getPageOfStaticSecurities])

    //
    // useState Hooks
    const [pendingSecuritiesCalls, setPendingSecuritiesCalls] = useState<
      PendingSecurityDataCall[]
    >([])
    const [gridApi, setGridApi] = useState<{
      api: GridApi
    } | null>(null)

    const [selectedDetailSecurityId, setSelectedDetailSecurityId] =
      useState<number>(0)

    const rowStyle = useMemo(
      () => ({
        borderTop: 0,
        borderBottom: 'none',
        /* AG Grid is not applying rowStyle to first row.Le
         https://github.com/ag-grid/ag-grid/issues/6993
         Workaround for now
      */
        height: '20px'
      }),
      []
    )

    const {
      handleColumnChange,
      getContextMenuItems,
      columnDefs,
      handleGridRowClick,
      handleGridRowDoubleClick,
      getRowClass,
      onRowExpandedChange,
      gridContext,
      rowSelection,
      theme
    } = useGridControls({
      gridApi: gridApi ? gridApi : undefined,
      gridIndex,
      canEditWatchlist,
      handle,
      setSelectedDetailSecurityId,
      onClickGridRow: onSelectionChanged,
      onDoubleClickGridRow: onRowDoubleClicked,
      setRowsAreOpen
    })

    // IG/HY Watchlist hooks
    useEffect(() => {
      if (
        watchlistIdSelected &&
        watchlistName &&
        DYNAMIC_WATCHLISTS.includes(watchlistName)
      ) {
        // fetch updated watchlist data --this is causing too many rerenders
        dispatch(setWatchListId(gridIndex, watchlistIdSelected))
      }
    }, [watchlists, watchlistName])

    // //
    // // Handle watchlist id change
    useEffect(() => {
      if (gridApi) {
        if (
          watchlistIdSelected &&
          watchlistName &&
          DYNAMIC_WATCHLISTS.includes(watchlistName)
        ) {
          gridApi.api.setColumnsVisible([MARKET_VOLUME], true)
        } else {
          gridApi.api.setColumnsVisible([MARKET_VOLUME], false)
        }
      }
    }, [gridApi?.api, watchlistIdSelected, watchlistName])

    // End IG/HY Watchlist hooks

    // ------------------ grid only, not in minegrid ------------------------ //

    useEffect(() => {
      for (const pendingSecuritiesCall of pendingSecuritiesCalls) {
        pendingSecuritiesCall.params.fail()
      }
      setPendingSecuritiesCalls([])
      setRowsAreOpen(false)
    }, [error])

    // force reload when the entire data source is invalidated
    useEffect(() => {
      if (currentPage === undefined && gridApi) {
        gridApi.api.refreshServerSide({ purge: true })
        setPendingSecuritiesCalls([])
        setRowsAreOpen(false)
      }
    }, [gridApi, currentPage])

    // datasource
    const getServerModelRows = useCallback(
      (params: IServerSideGetRowsParams<SecurityStaticData>) => {
        const { request } = params
        const startRow = request?.startRow ?? 0
        // grid may try to buffer above the first row
        if (startRow < 0) {
          params.fail()
          return
        }
        const page = (startRow ?? 0) / SECURITY_PAGE_SIZE
        dispatch(setCurrentPage(gridIndex, page))

        // check to see if we already have these rows
        const cachedSecurities = securitiesGetter.current(gridIndex, page)
        if (cachedSecurities?.length > 0) {
          // yes, don't need to store params
          params.success({ rowData: cachedSecurities })
        } else {
          // no,  don't have the rows, get them
          dispatch(fetchSecurities(gridIndex, page))
          setPendingSecuritiesCalls((oldCalls) => {
            return [...oldCalls, { page, params }]
          })
        }
      },
      [gridIndex]
    )

    // call success callback when rows come in
    useEffect(() => {
      const fulfilledPages: number[] = []
      /*  If we have any calls pending, check to see if we have the static data and
        call the success callback for any that have arrived
     */
      for (const pendingSecuritiesCall of pendingSecuritiesCalls) {
        const securities = getPageOfStaticSecurities(
          gridIndex,
          pendingSecuritiesCall.page
        )
        if (
          securities.length ||
          getIsPageLoaded(gridIndex, pendingSecuritiesCall.page)
        ) {
          fulfilledPages.push(pendingSecuritiesCall.page)
          pendingSecuritiesCall.params.success({ rowData: securities })
        }
      }
      // update pending calls to reflect the securities we have told the grid about
      if (fulfilledPages.length) {
        setPendingSecuritiesCalls(
          pendingSecuritiesCalls.filter(
            (call) => !fulfilledPages.includes(call.page)
          )
        )
      }
    }, [pendingSecuritiesCalls, getPageOfStaticSecurities])

    const serverSideDatasource = useMemo(() => {
      return {
        getRows: getServerModelRows
      }
    }, [])

    // loading overlay
    useEffect(() => {
      if (
        !pendingSecuritiesCalls.length &&
        currentPage !== undefined &&
        !loadedSecuritiesCount
      ) {
        gridApi?.api.showNoRowsOverlay()
      } else {
        gridApi?.api.hideOverlay()
      }
    }, [loadedSecuritiesCount, currentPage, pendingSecuritiesCalls])

    // ---------------------------------------------------------------------- //

    //
    // other useCallback Hooks
    const onGridReady = useCallback(({ api }: { api: GridApi }) => {
      if (!gridApi) {
        setGridApi({ api })
      }
    }, [])

    // ----------------- RENDER ----------------- //

    if (!columnDefs) {
      return null
    }

    return (
      <React.Fragment>
        <div
          className={`${theme} ${styles.gridStyle}`}
          data-testid={`securities-grid-${gridIndex}`}
        >
          <AgGridReact
            ref={gridRef}
            getContextMenuItems={getContextMenuItems}
            rowModelType="serverSide"
            serverSideDatasource={serverSideDatasource}
            masterDetail={true}
            embedFullWidthRows={true}
            detailCellRenderer={DepthDetailRenderer}
            detailRowHeight={170}
            onRowGroupOpened={onRowExpandedChange}
            suppressRowTransform={true}
            suppressColumnVirtualisation={true}
            cacheBlockSize={SECURITY_PAGE_SIZE}
            maxBlocksInCache={2}
            columnMenu="legacy"
            columnDefs={columnDefs}
            defaultColDef={defaultColumnDefinitions}
            suppressScrollOnNewData={true}
            components={components}
            groupHeaderHeight={0}
            headerHeight={20}
            rowHeight={20}
            rowSelection={rowSelection}
            onRowClicked={handleGridRowClick}
            onRowDoubleClicked={handleGridRowDoubleClick}
            onGridReady={onGridReady}
            getRowId={getRowId}
            enableCellTextSelection={true}
            singleClickEdit={true}
            overlayNoRowsTemplate={
              error
                ? isAdmin && gridIndex > 0
                  ? 'Watchlist not visible to active user'
                  : 'An error occurred during securities update.'
                : 'No security found for current filters and watchlist.'
            }
            overlayLoadingTemplate="Loading securities…"
            alwaysShowVerticalScroll={true}
            suppressDragLeaveHidesColumns={true}
            rowStyle={rowStyle}
            getRowClass={getRowClass}
            onDisplayedColumnsChanged={handleColumnChange}
            tooltipShowDelay={0}
            tooltipMouseTrack={true}
            suppressMenuHide={true}
            maxConcurrentDatasourceRequests={-1}
            enterNavigatesVerticallyAfterEdit={true}
            context={gridContext}
          />
        </div>
        {!!selectedDetailSecurityId && (
          <SecurityDetailsModalLazy
            securityId={selectedDetailSecurityId}
            isOpen={!!selectedDetailSecurityId}
            toggleIsOpen={() => setSelectedDetailSecurityId(0)}
          />
        )}
      </React.Fragment>
    )
  }
)

export default Grid
