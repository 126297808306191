import React, {
  Dispatch,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { isParentOf } from './dom'

/**
 *
 * @param value
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  return ref.current
}

/**
 *
 * @param ref
 */
export const useDropDownMenu = (ref: React.RefObject<HTMLDivElement>) => {
  const [displayMenu, setDisplayMenu] = useState(false)

  const hideDropDownMenu = useCallback(
    (e: MouseEvent) => {
      const isClickInside =
        ref.current !== null && isParentOf(ref.current, e.target as HTMLElement)

      if (!isClickInside && displayMenu) {
        setDisplayMenu(false)
      }
    },
    [ref.current, displayMenu]
  )

  useEffect(() => {
    if (displayMenu) {
      document.addEventListener('click', hideDropDownMenu)
    }

    return () => {
      document.removeEventListener('click', hideDropDownMenu)
    }
  }, [displayMenu])

  return {
    displayMenu,
    setDisplayMenu
  }
}

/**
 *
 * @param initialValue
 */
export const useRefState = <T extends NonNullable<unknown>>(
  initialValue: T
): [T, MutableRefObject<T>, Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState(initialValue)
  const stateRef = useRef(state)
  useEffect(() => {
    stateRef.current = state
  }, [state])
  return [state, stateRef, setState]
}

/**
 *
 * @param callback
 * @param delaySeconds
 */
export const useTimeout = (callback: () => void, delaySeconds: number) => {
  const [remainingSeconds, remainingSecondsRef, setRemainingSeconds] =
    useRefState(delaySeconds)

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (remainingSecondsRef.current === 1) {
        callback()
        clearInterval(intervalId)
      }
      setRemainingSeconds(remainingSecondsRef.current - 1)
    }, 1000)
  }, [])

  return remainingSeconds
}

/**
 * @param initial
 * @param callback
 */
type returnType = (
  initial: boolean,
  callback: () => void
) => [boolean, (newValue?: boolean) => void]

export const useToggleWithCancelCallback: returnType = (
  initial: boolean,
  cancelCallback: () => void
) => {
  const [value, setValue] = useState(initial)

  const onToggle = useCallback(
    (newValue?: boolean) => {
      const updateValue = newValue || !value

      if (!updateValue) {
        cancelCallback()
      }

      setValue(updateValue)
    },
    [value, setValue]
  )

  return [value, onToggle]
}
