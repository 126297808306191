import { CustomDetailCellRendererProps } from '@ag-grid-community/react'
import React from 'react'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getSecurityStaticDataById } from '../../store/securities/selectors'
import { useAppSelector } from '../../store/types'
import { getAllowOrderEntry } from '../../store/webSettings/selectors'
import styles from './Depth/DepthOfMarket.module.scss'
import { PassiveDepthOfMarket } from './PassiveDepthOfMarket'

const DepthDetailRenderer = ({
  data,
  pinned,
  context
}: CustomDetailCellRendererProps<SecurityStaticData>) => {
  // grid doesn't get updates for performance reasons--get latest security
  const allowOrderEntry = useAppSelector(getAllowOrderEntry)
  const security = useAppSelector(getSecurityStaticDataById)(data?.id ?? -1)

  if (!security || pinned) return null
  return (
    <div className={styles.depthDetail}>
      <PassiveDepthOfMarket
        security={security}
        gridIndex={context?.gridIndex ?? 0}
        isPopout={false}
        showOrderEntry={allowOrderEntry}
      />
    </div>
  )
}

export default DepthDetailRenderer
