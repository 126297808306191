import {
  faArrowRightToBracket,
  faClipboardList,
  faExclamationTriangle,
  faPen,
  faTimes
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'

import {
  faArrowsToDottedLine,
  faPlus
} from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import AdvancedFilter from '../../components/AdvancedFilter/AdvancedFilter'
import DropDownUploadOrdersByDatePicker from '../../components/DropdownUpload/DropDownUploadOrdersByDatePicker'
import DropDownWatchlistMenu from '../../components/DropDownWatchlistMenu/DropDownWatchlistMenu'
import MyOrdersControlButtons from '../../components/MyOrdersControlButtons/MyOrdersControlButtons'

import QuoteReliabilityDropDownMenu from '../../components/QuoteReliability/QuoteReliabilityDropDownMenu'
import { useListTradingNavigation } from '../../helpers/hooks/useListTradingNavigation'

import { fetchBooks } from '../../store/books/actions'
import { getBooks } from '../../store/books/selectors'
import { getOrderValidations } from '../../store/order/selectors'
import {
  fetchSecurities,
  removeGrid,
  setIsMine,
  setShowLive,
  sortValidationsToTopAction
} from '../../store/securities/actions'
import {
  getIsMine,
  getShowLive,
  getWatchlistId
} from '../../store/securities/selectors'
import { useAppSelector } from '../../store/types'
import { toggleDropdownState } from '../../store/upload/actions'
import { getDropdownState } from '../../store/upload/selectors'
import { DropdownState } from '../../store/upload/types'
import {
  cancelAllWatchListOrdersAction,
  fetchWatchListsAction
} from '../../store/watchList/actions'
import { getWatchList } from '../../store/watchList/selectors'

import stop_sign from '../../assets/images/stop_sign.svg'

import styles from './bondListHeader.module.scss'
import BondsListHeaderSearch from './BondsListHeaderSearch'
import HeaderButton from './HeaderButton'

import { useOpenFin } from '../../app/openFinContext'
import { getSelectedUser, getUserFromUserId } from '../../store/users/selectors'
import {
  getAllowOrderEntry,
  getIsAdmin
} from '../../store/webSettings/selectors'

interface Props {
  gridIndex: number
  myOrdersOpen: boolean
  toggleMyOrders: () => void
  advancedFilterActive: boolean
  setAdvancedFilterActive: (b: boolean) => void
  showCollapseRows: boolean
  collapseRows: () => void
  canEditWatchList: boolean
}

const BondListHeader: React.FC<Props> = ({
  gridIndex,
  myOrdersOpen,
  toggleMyOrders,
  advancedFilterActive,
  setAdvancedFilterActive,
  canEditWatchList,
  showCollapseRows,
  collapseRows
}) => {
  const { fin } = useOpenFin()
  const watchlists = useAppSelector(getWatchList)
  const dispatch = useDispatch()
  const watchlistIdSelected = useAppSelector(getWatchlistId)(gridIndex)
  const mineChecked = useAppSelector(getIsMine)(gridIndex)
  const showLiveChecked = useAppSelector(getShowLive)(gridIndex)
  const books = useAppSelector(getBooks)
  const dropdownState = useAppSelector(getDropdownState)(gridIndex)
  const orderValidations = useAppSelector(getOrderValidations)
  const selUserId = useAppSelector(getSelectedUser)
  const selectedUser = useAppSelector(getUserFromUserId)(selUserId)
  const selectedAdminUser = `${selectedUser?.custName}: ${selectedUser?.userName}`
  const isAdmin = useAppSelector(getIsAdmin)
  const [securitiesWithOrderValidation, setSecuritiesWithOrderValidation] =
    useState([] as number[])
  const allowOrderEntry = useAppSelector(getAllowOrderEntry)

  const watchListIcon = canEditWatchList ? faPen : faPlus
  const watchListTip = canEditWatchList ? 'Edit Watchlist' : 'New Watchlist'

  const { hasTradingRights, showLT, tradeWatchlist, wlName } =
    useListTradingNavigation(watchlistIdSelected)

  const nextDropdownState = useMemo<DropdownState>(() => {
    switch (dropdownState) {
      case 'closed':
        return canEditWatchList ? 'upload' : 'newWatchlist'
      default:
        return 'closed'
    }
  }, [dropdownState, canEditWatchList])

  useEffect(() => {
    const securitiesWithError = orderValidations.map((v) => v.securityId)
    setSecuritiesWithOrderValidation(securitiesWithError)
  }, [JSON.stringify(orderValidations)])

  const sortByOrderValidation = () => {
    dispatch(
      sortValidationsToTopAction(gridIndex, securitiesWithOrderValidation)
    )
    dispatch(fetchSecurities(gridIndex, 0, securitiesWithOrderValidation))
  }

  const handleCloseButton = useCallback(() => {
    dispatch(removeGrid(gridIndex))
  }, [])

  useEffect(() => {
    if (watchlists === undefined) {
      dispatch(fetchWatchListsAction(gridIndex))
    }
  }, [watchlists])

  useEffect(() => {
    if (books === undefined) {
      dispatch(fetchBooks())
    }
  }, [books])

  const cancelAllOrders = () => {
    dispatch(cancelAllWatchListOrdersAction(gridIndex, watchlistIdSelected))
  }

  const onCheckMine = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    dispatch(setIsMine(gridIndex, checked))
  }, [])

  const onCheckShowLive = useCallback((e: React.ChangeEvent) => {
    const { checked } = e.target as HTMLInputElement
    dispatch(setShowLive(gridIndex, checked))
  }, [])

  const toggleUpload = useCallback(() => {
    dispatch(toggleDropdownState(gridIndex, nextDropdownState))
  }, [dropdownState, nextDropdownState])

  return (
    <div
      data-testid={`grid-${gridIndex}-header`}
      className={cx(
        styles.gridHeaderWrapper,
        gridIndex !== 0 && styles.addDrag
      )}
    >
      <div
        className={cx(
          styles.gridHeader,
          myOrdersOpen && styles.rightBorderAdjust
        )}
      >
        <div className={styles.headerButtons}>
          <div className={styles.headerDropdown}>
            <DropDownWatchlistMenu
              setActive={setAdvancedFilterActive}
              gridIndex={gridIndex}
              watchLists={watchlists || []}
              className={styles.dropdownWatchlistContainer}
            />
            <BondsListHeaderSearch gridIndex={gridIndex} />
          </div>

          <div className={cx('pretty p-switch', styles.checkboxWrapper)}>
            <input
              type="checkbox"
              name="checkbox-showlive"
              onChange={onCheckShowLive}
              checked={showLiveChecked}
            />
            <div className="state p-primary">
              <label>Show Live</label>
            </div>
          </div>

          {allowOrderEntry && (
            <div className={cx('pretty p-switch', styles.checkboxWrapper)}>
              <input
                type="checkbox"
                name="checkbox-mine"
                onChange={onCheckMine}
                checked={mineChecked}
                disabled={watchlistIdSelected === 0}
              />
              <div className="state p-primary">
                <label>Mine</label>
              </div>
            </div>
          )}

          <div className={styles.headerIcons}>
            <AdvancedFilter
              active={advancedFilterActive}
              setActive={setAdvancedFilterActive}
              gridIndex={gridIndex}
            />

            <QuoteReliabilityDropDownMenu gridIndex={gridIndex} />

            {(fin || !gridIndex) && (
              <HeaderButton
                active={dropdownState === 'upload'}
                toggle={toggleUpload}
                data-testid="manualordersbutton"
                faIcon={watchListIcon}
                dataFor="editList"
                title={watchListTip}
              />
            )}

            {showLT &&
              hasTradingRights &&
              wlName?.length &&
              canEditWatchList && (
                <HeaderButton
                  active={false}
                  dataFor="ltNavigation"
                  data-testid="trade-selected-watchlist"
                  toggle={tradeWatchlist}
                  faIcon={faClipboardList}
                  title={`List Trading for ${wlName}`}
                />
              )}

            {allowOrderEntry && (
              <HeaderButton
                data-testid="myordersbutton"
                className={cx(myOrdersOpen && styles.myOrdersOpen)}
                active={myOrdersOpen}
                toggle={toggleMyOrders}
                faIcon={faArrowRightToBracket}
                title={`${myOrdersOpen ? 'Hide' : 'Show'} My Orders`}
              />
            )}

            {gridIndex !== 0 && (
              <HeaderButton
                data-testid="removegrid"
                dataFor="closeWatchlist"
                active={false}
                toggle={handleCloseButton}
                faIcon={faTimes}
                className={styles.closeWatchlist}
                title="Close Watchlist"
              />
            )}
            {showCollapseRows && (
              <HeaderButton
                active={false}
                data-testid="collapseRows"
                faIcon={faArrowsToDottedLine}
                title="Collapse Rows"
                toggle={collapseRows}
              />
            )}
          </div>
        </div>
      </div>
      {isAdmin && selectedUser && gridIndex !== 0 && (
        <div className={styles.adminText}>{selectedAdminUser}</div>
      )}
      {myOrdersOpen && (
        <div className={styles.myOrdersHeader} data-testid="my-orders-header">
          <span className={styles.myOrdersLabel}>
            My Orders
            <img
              src={stop_sign}
              alt="stop sign icon"
              className={styles.stopIcon}
              data-testid="myordersstopbutton"
              onClick={cancelAllOrders}
              title="Cancel Watchlist Orders"
            />
            {securitiesWithOrderValidation.length > 0 && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                onClick={sortByOrderValidation}
              />
            )}
          </span>
          <MyOrdersControlButtons />
          <div className={styles.dFlex}>
            <span className="pretty p-switch">
              <input
                type="checkbox"
                name="checkbox-mine"
                onChange={onCheckMine}
                checked={mineChecked}
                disabled={watchlistIdSelected === 0}
              />
              <div className="state p-primary">
                <label>Mine</label>
              </div>
            </span>
            {(fin || !gridIndex) && (
              <HeaderButton
                active={dropdownState === 'upload'}
                toggle={toggleUpload}
                data-testid="manualordersbutton"
                faIcon={watchListIcon}
                dataFor="editList"
                title={watchListTip}
              />
            )}
            <span className={styles.myOrdersParameters}>
              <DropDownUploadOrdersByDatePicker gridIndex={gridIndex} />
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default BondListHeader
