import { Action } from 'redux'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap, takeUntil } from 'rxjs/operators'
import { getHub } from '../../../helpers/hub'
import { logError } from '../../ws/actions'
import {
  aggressAttemptAddAggressAttempt,
  aggressAttemptFetchFail
} from './actions'
import {
  AggressAttempt,
  AGGRESSATTEMPT_FETCH,
  AGGRESSATTEMPT_FETCH_CANCEL,
  AggressAttemptFetchAction
} from './types'

const fetchAggressAttemptEpic: Epic<Action> = (action$) =>
  action$.pipe(
    ofType(AGGRESSATTEMPT_FETCH),
    delay(1000),
    switchMap((action: AggressAttemptFetchAction) => {
      const getAggressAttempts$ =
        getHub().stream<AggressAttempt[]>('GetAggressAttempts')
      return getAggressAttempts$.pipe(
        map((aggressAttempt) => {
          return aggressAttemptAddAggressAttempt(aggressAttempt)
        }),
        takeUntil(action$.pipe(ofType(AGGRESSATTEMPT_FETCH_CANCEL))),
        catchError((err) => of(aggressAttemptFetchFail(err), logError(err)))
      )
    })
  )

export default combineEpics(fetchAggressAttemptEpic)
