import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo } from 'react'
import styles from '../grid.module.scss'

interface Props {
  status?: string
  onClick?: () => void
  icon?: IconProp
  title?: string
  testId: string
}

const OrderStatusTdContent = ({
  status,
  onClick,
  icon,
  title,
  testId
}: Props) => {
  return (
    <div className={styles.status}>
      {status}
      {icon && (
        <div
          data-testid={`aggress-table-${testId}`}
          onClick={onClick}
          title={title}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
    </div>
  )
}

export default memo(OrderStatusTdContent)
