import { CustomCellRendererProps } from '@ag-grid-community/react'
import React, { ReactNode } from 'react'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'
import { useListTradingAggressOrders } from '../hooks/useListTradingAggressOrders'
import DepthOfMarketControls from './DepthOfMarketControls'

import styles from '../grid.module.scss'

// TODO: ListTradingSecurityFieldRenderer should handle this
const InnerStatus = ({
  children,
  security,
  watchlistId
}: {
  security: ListTradingSecurity
  watchlistId: number
  children?: ReactNode
}) => {
  const { orderSummary } = useListTradingAggressOrders(
    security.id,
    security.isBid,
    watchlistId,
    security.bestOrder
  )

  return (
    <>
      {orderSummary.map((status) => (
        <span
          key={status[0]}
          className={status[0] === 'Error' ? styles.errorMsg : ''}
        >
          {status[0]} ({status[1]}) 
        </span>
      ))}
      {!!orderSummary.length && children}
    </>
  )
}

const StatusRenderer = (
  props: CustomCellRendererProps<SecurityStaticData, any, TradingListContext>
) => {
  const { data, context } = props
  const security = useGetListTradingSecurity(data?.id)

  if (!security || !context.watchlistId) return <></>

  return (
    <InnerStatus security={security} watchlistId={context.watchlistId}>
      <DepthOfMarketControls {...props} />
    </InnerStatus>
  )
}

export default StatusRenderer
