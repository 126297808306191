import React, { FC, useEffect } from 'react'
import { useOpenFin } from '../../app/openFinContext'
import {
  benchmarkDataFetch,
  unsubscribeFromBenchmark
} from '../../store/benchmarkData/actions'
import {
  getBenchmarkData,
  getBenchmarkDataPending
} from '../../store/benchmarkData/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import Loading from '../Loading/Loading'
import BenchmarkData from './BenchmarkData'

const BenchmarkContainer: FC = () => {
  const dispatch = useAppDispatch()
  const pending = useAppSelector(getBenchmarkDataPending)
  const { fin } = useOpenFin()
  useEffect(() => {
    dispatch(benchmarkDataFetch())
    return () => {
      dispatch(unsubscribeFromBenchmark())
    }
  }, [])
  const benchmarkData = useAppSelector(getBenchmarkData)
  if (fin && (pending || !benchmarkData)) return <Loading />
  if (!benchmarkData) return null

  return <BenchmarkData benchmarkData={benchmarkData} />
}

export default BenchmarkContainer
