import {
  faArrowsCross,
  faCircleInfo,
  faTimes,
  faTriangleExclamation
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

import React, { memo, useMemo, useState } from 'react'
import { formatSpread } from '../../../components/Activity/AlertDescription/helpers'
import styles from '../../../components/DepthOfMarket/Depth/DepthOfMarket.module.scss'
import { capitalize } from '../../../components/DepthOfMarket/helpers'
import {
  formatCurrency,
  formatSize,
  formatTreasuryPrice
} from '../../../helpers/formatting'
import { getMarketForSecurity } from '../../../store/depthOfMarket/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { getListTradeError } from '../../../store/order/selectors'

import { Order } from '../../../store/order/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useAppSelector } from '../../../store/types'
import { WatchList } from '../../../store/watchList/types'
import tableStyles from '../grid.module.scss'
import { convertToLocalTz, getDisplayStatus, isPending } from '../helpers'
import { useListTradingAggressOrders } from '../hooks/useListTradingAggressOrders'
import OrderPendingCountdown from './OrderPendingCountdown'
import OrderStatusTdContent from './OrderStatusTdContent'
import SelectAggressOrderToCancelCheckbox from './SelectAggressOrderToCancelCheckbox'

interface Props {
  ltSecurity: ListTradingSecurity
  security: SecurityStaticData
  watchlistId: WatchList['id']
  showDetails: (orderId: string | null) => void
  currency?: string
}

const getDirection = (order: Order) => {
  if (order.status === 'accepted') {
    return order.type === 'buy' ? 'Bought' : 'Sold'
  }
  return capitalize(order.type)
}

const getPricingInfo = (order: Order, currency?: string) => {
  const spreadAmt =
    order.spread !== undefined ? `${formatSpread(order.spread ?? 0)} | ` : ''
  return spreadAmt + formatCurrency(order.price, currency)
}

const AggressOrdersTable = ({
  ltSecurity,
  security,
  watchlistId,
  showDetails,
  currency
}: Props) => {
  const {
    orders: aggressOrders,
    hedgeOrders,
    selectedOrderIds,
    toggleOrderCancel
  } = useListTradingAggressOrders(ltSecurity.id, ltSecurity.isBid, watchlistId)

  const getError = useAppSelector(getListTradeError)
  const depthOrders = useAppSelector(getMarketForSecurity)(
    0,
    ltSecurity.id,
    ltSecurity.isBid ? 'buy' : 'sell'
  )
  const bestTrade = ltSecurity.bestOrder
  const securityOrderType = ltSecurity.isBid ? 'buy' : 'sell'

  const errRows = useMemo(() => {
    const error = bestTrade ? getError(bestTrade.id) : undefined
    const best = { ...bestTrade, error }
    const errors = depthOrders.map((order) => ({
      ...order,
      error: getError(order.id)
    }))
    if (best?.error) {
      // @ts-ignore
      errors.unshift(best)
    }
    return errors.filter((err) => err.error)
  }, [getError, depthOrders, bestTrade])

  const [errIndex, setErrIndex] = useState<number>(-1)

  return (
    <>
      <table
        className={cx(styles.ltStatus, tableStyles.aggressOrderTable)}
        data-testid={`listTrading-aggress-table-${ltSecurity.id}`}
      >
        <tbody>
          {aggressOrders.map((aggressOrder) => {
            const status = getDisplayStatus(aggressOrder?.status)
            const amount = getPricingInfo(aggressOrder, currency)
            const orderTime = aggressOrder.tradeTime || aggressOrder.submitTime
            return (
              <tr key={aggressOrder.id}>
                <td>
                  <SelectAggressOrderToCancelCheckbox
                    onChange={toggleOrderCancel}
                    isChecked={selectedOrderIds.includes(aggressOrder.id)}
                    orderId={aggressOrder.id}
                    enabled={isPending(aggressOrder)}
                  />
                </td>
                <td>
                  {isPending(aggressOrder) ? (
                    <OrderPendingCountdown order={aggressOrder} />
                  ) : (
                    convertToLocalTz(orderTime)
                  )}
                </td>
                <td>
                  <OrderStatusTdContent
                    onClick={() => showDetails(aggressOrder.id)}
                    status={status}
                    icon={status === 'Traded' ? faCircleInfo : undefined}
                    title="View Trade Confirm"
                    testId={`lt-${ltSecurity.id}-${aggressOrder.id}-info`}
                  />
                </td>
                <td>{getDirection(aggressOrder)}</td>
                <td>
                  {formatSize(
                    aggressOrder.size,
                    aggressOrder.totalSize,
                    false,
                    false
                  )}
                </td>
                <td>{amount}</td>
              </tr>
            )
          })}
          {hedgeOrders.map((row) => {
            const amount = formatTreasuryPrice(row.price)
            return (
              <tr key={row.id}>
                <td>{/* icon col*/}</td>
                <td>{convertToLocalTz(row.tradeTime)}</td>
                <td>
                  <OrderStatusTdContent
                    onClick={() => showDetails(row.id)}
                    status="Crossed"
                    icon={faArrowsCross}
                    title="View Trade Confirm"
                    testId={`lt-${ltSecurity.id}-${row.id}-hedge`}
                  />
                </td>
                <td>{getDirection(row)}</td>
                <td>{formatSize(row.size, row.totalSize, false, false)}</td>
                <td className={tableStyles.hedgeInfo}>
                  {security.benchmarkName} {amount}
                </td>
              </tr>
            )
          })}
          {errRows.map((row, i) => {
            const amount = getPricingInfo(row, currency)
            const orderTime = row.tradeTime || row.submitTime
            return (
              <tr
                key={row.id}
                className={tableStyles.error}
                title={row.error?.error.message}
              >
                <td>{/* icon col*/}</td>
                <td>{convertToLocalTz(orderTime)}</td>
                <td>
                  <OrderStatusTdContent
                    onClick={() => setErrIndex(i)}
                    status="Failed"
                    icon={faTriangleExclamation}
                    testId={`lt-${ltSecurity.id}-${row.id}-error`}
                  />
                </td>
                <td>{getDirection({ ...row, type: securityOrderType })}</td>
                <td>
                  {formatSize(
                    row.error?.size ?? row.size,
                    row.totalSize,
                    false,
                    false
                  )}
                </td>
                <td>{amount}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {errIndex > -1 && errRows[errIndex]?.error && (
        <div
          className={tableStyles.aggressOrderError}
          onClick={() => setErrIndex(-1)}
          data-testid={`lt-aggress-error-msg-${ltSecurity.id}`}
        >
          <FontAwesomeIcon icon={faTimes} />
          <p>Error: {errRows[errIndex].error.error.message}</p>
        </div>
      )}
    </>
  )
}

export default memo(AggressOrdersTable)
