import cx from 'classnames'
import React, {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { noop } from 'rxjs'
import styles from '../bondListStyle.module.scss'
import { OrderCellEditorParams } from './types'

const agGridKeysToPrevent = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Enter']

const MyOrderEditor = ({
  manager,
  data: security,
  field,
  stopEditing
}: OrderCellEditorParams) => {
  const styleConditions = manager.getStyleConditions(security?.id ?? 0, field)
  const classNames = cx(styles.item, {
    [styles.isPending]: styleConditions.isPending,
    [styles.hasFocus]: styleConditions.hasFocus,
    [styles.hasError]: styleConditions.hasError
  })

  const initialValue = manager.getInitialFieldValueForSecurity(
    security?.id ?? -1,
    field
  )
  const [value, setValue] = useState(initialValue)

  const submitFieldValue = useCallback(() => {
    if (!security) return
    if (value !== initialValue) {
      manager.setOrderFieldByName(field, `${value}`, security)
    }
  }, [field, manager, value, security, initialValue])

  // this component is only instantiated when the column is being edited,
  // so we focus the input on first render
  const refInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    refInput.current?.focus()
    refInput.current?.select()
    if (security) manager.focusOrder(security.id)
  }, [])

  const onInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (agGridKeysToPrevent.includes(e.key)) {
        e.stopPropagation()
      }
      if (e.key === 'Enter') {
        if (value !== initialValue) {
          manager.stageAndSubmitOrder(field, `${value}`, security)
        } else {
          manager.submitStagedOrder(security)
        }
        stopEditing()
        onRemove.current = noop
      }
    },
    [field, manager, value, security, stopEditing]
  )

  const onInputDoubleckick = useCallback((e: MouseEvent) => {
    e.stopPropagation()
  }, [])

  const onCellFocus = useCallback(() => {
    if (security) manager.focusOrder(security.id)
  }, [manager])

  const stopMyEditing = useCallback(() => {
    submitFieldValue()
  }, [submitFieldValue])

  const onRemove = useRef<() => void>(stopMyEditing)
  onRemove.current = stopMyEditing

  useEffect(() => {
    return () => onRemove.current?.()
  }, [])

  return (
    <span
      className={classNames}
      data-testid={`my${manager.orderTypeName}${field}-${security?.isin ?? ''}`}
      onFocus={onCellFocus}
    >
      <input
        ref={refInput}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onDoubleClick={onInputDoubleckick}
        onKeyDownCapture={onInputKeyDown}
        data-testid={`${security?.isin ?? ''}-${
          manager.orderTypeName
        }-${field}-input`}
        size={11}
      />
    </span>
  )
}

export default MyOrderEditor
