import { faTimes } from '@awesome.me/kit-5de77b2c02/icons/duotone/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { FC } from 'react'
import { getSelectedRow } from '../../../store/admin/adminTradeBlotter/selectors'
import { useAppSelector } from '../../../store/types'
import Modal from '../../GenericModal/Modal'
import modalStyles from '../../GenericModal/modal.module.scss'
import styles from './AdminTradeBlotter.module.scss'
interface Props {
  closeFunc: () => void
  paramId: string
}
const TradeBlotterModal: FC<Props> = (props: Props) => {
  const closeFunc = props.closeFunc
  const selectedRow = useAppSelector(getSelectedRow)(props.paramId)
  if (!selectedRow) return null

  return (
    <Modal>
      <button
        data-qa="close"
        type="button"
        className={styles.close}
        onClick={closeFunc}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className={cx(modalStyles.confirmContent, styles.confirmText)}>
        {selectedRow.confirmText}
      </div>
    </Modal>
  )
}
export default TradeBlotterModal
