import {
  IAggFunc,
  IAggFuncParams,
  IRowNode,
  RowGroupOpenedEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { Dispatch, RefObject, SetStateAction } from 'react'

// TODO: can we replace these with the built in aggregation functions?
export const simpleSum: IAggFunc = ({ values }) => {
  let sum = 0
  values.forEach((value: any) => {
    sum += Number(value)
  })
  return sum.toLocaleString()
}

export const pctAvg = (
  argNum: string,
  argDenom: string[],
  params: IAggFuncParams
) => {
  let total = 0
  let sum = 0
  params.rowNode.childrenAfterFilter?.forEach((node: any) => {
    for (const denom of argDenom) {
      total += node.data[denom]
    }
    sum += node.data[argNum]
  })
  if (total === 0) {
    return '-'
  }
  const pct = (sum / total) * 100
  return pct.toFixed(2)
}

export const sumDate: IAggFunc = (params) => {
  let sum = 0
  const today = new Date()
  params.values.forEach((value: number | string | Date) => {
    const valAsMoment = new Date(value)

    if (valAsMoment.getDate() === today.getDate()) {
      sum++
    }
  })
  return sum.toString()
}

export const collapseAllRows = <T>(gridRef: RefObject<AgGridReact<T>>) => {
  if (!gridRef.current?.api) return
  const api = gridRef.current?.api

  api.forEachNode((node: IRowNode<T>) => node.setExpanded(false))
}

export const handleRowExpandedChange = <T>(
  e: RowGroupOpenedEvent,
  setShowCollapseRows: Dispatch<SetStateAction<boolean>>
) => {
  let expandedCount = 0
  e.api.forEachNode((node: IRowNode<T>) => {
    expandedCount += Number(node?.expanded ?? 0)
  })
  setShowCollapseRows(!!expandedCount)
  const rowNodes = [e.node]
  e.api.redrawRows({ rowNodes })
}
