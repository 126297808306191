import { Action } from 'redux'

const NAMESPACE = `benchmarkdata.`

//
// ACTIONS
/// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Fetch
 */
export const BENCHMARKDATA_FETCH = `${NAMESPACE}fetch`
export interface BenchmarkDataFetchAction extends Action {
  type: typeof BENCHMARKDATA_FETCH
}

/**
 * Fetch cancel
 */
export const BENCHMARKDATA_FETCH_CANCEL = `${NAMESPACE}fetchCancel`
export interface BenchmarkDataFetchCancelAction extends Action {
  type: typeof BENCHMARKDATA_FETCH_CANCEL
}

export const BENCHMARKDATA_FETCH_FAIL = `${NAMESPACE}fetch`

export interface BenchmarkDataFetchFailAction extends Action {
  type: typeof BENCHMARKDATA_FETCH_FAIL
  payload: any
  error: true
}

export const BENCHMARKDATA_SUCCESS = `${NAMESPACE}success`
export interface BenchmarkDataSuccessAction extends Action {
  type: typeof BENCHMARKDATA_SUCCESS
  payload: BenchmarkInfo
}

export const BENCHMARKDATA_SET_SEARCH = `${NAMESPACE}search`
export interface BenchmarkDataSetSearchAction extends Action {
  type: typeof BENCHMARKDATA_SET_SEARCH
  payload: string
}

export type BenchmarkDataAction =
  | BenchmarkDataFetchAction
  | BenchmarkDataFetchFailAction
  | BenchmarkDataFetchCancelAction
  | BenchmarkDataSuccessAction
  | BenchmarkDataSetSearchAction

export interface BenchmarkInfo {
  totalOrders: number
  notional: number
  benchmarkData: BenchmarkList[]
}

export interface BenchmarkList {
  id: number
  benchmarkName: string
  bestBid: string
  bestOffer: string
  yield: number
  isin: string
}
